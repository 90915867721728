import { Box } from '@mui/material';
import PageHeader from 'components/PageHeader/PageHeader';
import { useParams, useSearchParams } from 'react-router-dom';
import { routes } from 'utils/routes';
import * as Styled from './ViewWorkspacePage.styled';
import { WorkspacePageTabs } from './ViewWorkspacePage.types';
import EditWorkspace from './components/EditWorkspace/EditWorkspace';
import WorkspaceCredentials from './components/WorkspaceCredentials/WorkspaceCredentials';
import WorkspaceMembers from './components/WorkspaceMembers/WorkspaceMembers';
import WorkspaceSolutions from './components/WorkspaceSolutions/WorkspaceSolutions';

const ViewWorkspacePage = () => {
  const { workspaceId = '' } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = (searchParams.get('tab') ?? 'members') as WorkspacePageTabs;

  const handleOnChangeTabs = (tabValue: WorkspacePageTabs) => {
    setSearchParams(`tab=${tabValue}`);
  };

  return (
    <>
      <PageHeader title="Manage workspace" backTo={routes.adminWorkspaces()} size="small" />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <EditWorkspace workspaceId={workspaceId} />

        <Tabs<WorkspacePageTabs>
          onTabSelected={handleOnChangeTabs}
          selectedTab={selectedTab}
          items={[
            {
              label: 'Members',
              value: 'members',
            },
            {
              label: 'Solutions',
              value: 'solutions',
            },
            {
              label: 'APIs',
              value: 'credentials',
            },
          ]}
        />

        {selectedTab === 'members' && <WorkspaceMembers workspaceId={workspaceId} />}
        {selectedTab === 'solutions' && <WorkspaceSolutions workspaceId={workspaceId} />}
        {selectedTab === 'credentials' && <WorkspaceCredentials workspaceId={workspaceId} />}
      </Box>
    </>
  );
};

interface TabsProps<T = string> {
  items: { value: T; label: string }[];
  onTabSelected: (value: T) => void;
  selectedTab: T;
}

//custom tab component
function Tabs<T>({ items, onTabSelected, selectedTab }: TabsProps<T>) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
      }}
    >
      {items.map((item) => {
        return (
          <Styled.TabButton
            onClick={() => {
              onTabSelected(item.value);
            }}
            variant={selectedTab === item.value ? 'contained' : 'text'}
            key={item.value as string}
          >
            {item.label}
          </Styled.TabButton>
        );
      })}
    </Box>
  );
}

export default ViewWorkspacePage;
