import ErrorIcon from '@mui/icons-material/Error';
import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';
import useUploadImage from 'api/platformApi/services/uploadImageService/useUploadImage';
import { useCreateWorkspace } from 'api/platformApi/services/workspacesService/hooks';
import AppDrawer from 'components/AppDrawer/AppDrawer';
import AppDrawerContent from 'components/AppDrawer/components/AppDrawerContent/AppDrawerContent';
import AppDrawerFooter from 'components/AppDrawer/components/AppDrawerFooter/AppDrawerFooter';
import AppDrawerHeader from 'components/AppDrawer/components/AppDrawerHeader/AppDrawerHeader';
import LogoField from 'components/LogoField/LogoField';
import { AlertList } from 'components/ui/AlertList/AlertList.styles';
import MFButtonLoader from 'components/ui/MFButtonLoader/MFButtonLoader';
import RHFMonkTextField from 'components/ui/rhf-components/RHFMonkTextField/RHFMonkTextField';
import { requiredValidation } from 'components/ui/rhf-components/utils/validationRules';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { routes } from 'utils/routes';
import {
  CreateWorkspaceDrawerProps,
  CreateWorkspaceFormFields,
} from './CreateWorkspaceDrawer.types';
import { fromTitleToDefaultLogo } from './CreateWorkspaceDrawer.utils';

const CREATE_WORKSPACE_FORM_ID = 'create-workspace-form';

const CreateWorkspaceDrawer: FC<CreateWorkspaceDrawerProps> = (props) => {
  const navigate = useNavigate();
  const snackbar = useSnackbarContext();
  const [fileErrors, setFileErrors] = useState<string[]>([]);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
    setError,
  } = useForm<CreateWorkspaceFormFields>({
    mode: 'all',
    defaultValues: {
      name: '',
    },
  });

  const uploadImageMutation = useUploadImage();
  const createWorkspaceMutation = useCreateWorkspace({
    onError(error) {
      if (error.scope === 'field' && error.field === 'name') {
        setError(error.field, { message: error.message });
      }
    },
    onSuccess: (createWorkspaceResponse) => {
      reset();
      props.onClose();
      snackbar.success('Workspace created successfully');

      navigate(routes.adminWorkspace(createWorkspaceResponse.id));
    },
  });

  const onCreateWorkspaceSubmit: SubmitHandler<CreateWorkspaceFormFields> = async ({
    logoFile,
    name,
  }) => {
    const logoUrl = logoFile
      ? (await uploadImageMutation.mutateAsync(logoFile)).url
      : fromTitleToDefaultLogo(name);

    await createWorkspaceMutation.mutateAsync({
      name,
      logoUrl,
    });
  };

  const isSubmitDisabled = !isValid || isSubmitting;

  return (
    <AppDrawer open={props.open} onClose={props.onClose}>
      <AppDrawerHeader onClose={props.onClose}>
        <Typography variant="h6">Create workspace</Typography>
      </AppDrawerHeader>

      <AppDrawerContent>
        <form id={CREATE_WORKSPACE_FORM_ID} onSubmit={handleSubmit(onCreateWorkspaceSubmit)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2.5,
            }}
          >
            {createWorkspaceMutation.isError && createWorkspaceMutation.error.scope === 'alert' && (
              <Alert severity="error">{createWorkspaceMutation.error.message}</Alert>
            )}

            {fileErrors.length > 0 && (
              <Alert
                severity="error"
                icon={<ErrorIcon />}
                onClose={() => {
                  setFileErrors([]);
                }}
              >
                <AlertTitle>Image could not be uploaded</AlertTitle>
                <AlertList>
                  {fileErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </AlertList>
              </Alert>
            )}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Box
                sx={{
                  alignItems: 'flex-start',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 3,
                }}
              >
                <Controller
                  control={control}
                  name="logoFile"
                  render={({ field, formState }) => (
                    <LogoField
                      alt="New workspace logo"
                      disabled={formState.isSubmitting}
                      loading={formState.isSubmitting}
                      onChange={(value) => {
                        setFileErrors([]);
                        field.onChange(value);
                      }}
                      onError={setFileErrors}
                      value={field.value}
                    />
                  )}
                />

                <RHFMonkTextField
                  name="name"
                  control={control}
                  rules={{ ...requiredValidation }}
                  label="Workspace name"
                  placeholder="Enter name..."
                  disabled={isSubmitting}
                  required
                  fullWidth
                />
              </Box>

              <Alert severity="info">
                <AlertTitle>Logo upload requirements</AlertTitle>
                <AlertList>
                  <li>300x300 pixels</li>
                  <li>.png</li>
                  <li>Maximum file size of 400KB</li>
                </AlertList>
              </Alert>
            </Box>
          </Box>
        </form>
      </AppDrawerContent>

      <AppDrawerFooter>
        <Box
          sx={{
            marginLeft: 'auto',
          }}
        >
          <Button
            disabled={isSubmitDisabled}
            form={CREATE_WORKSPACE_FORM_ID}
            type="submit"
            variant="contained"
          >
            {isSubmitting ? <MFButtonLoader /> : `Create`}
          </Button>
        </Box>
      </AppDrawerFooter>
    </AppDrawer>
  );
};

export default CreateWorkspaceDrawer;
