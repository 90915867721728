import { Box, Typography } from '@mui/material';
import FitText from 'components/FitText';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import {
  WorkspaceInfoBackground,
  WorkspaceInfoContainer,
  WorkspaceInfoDescription,
} from './DashboardWorkspaceInfo.styles';

const DashboardWorkspaceInfo = () => {
  const workspace = useActiveWorkspace();
  const name = `${workspace?.name} workspace`;
  const description = (() => {
    if (workspace?.description && workspace.description.trim().length > 0) {
      return workspace.description;
    }

    return `Welcome to your ${name}, where you can collaborate on various public projects and start new ones with your peers. Training materials, news, and apps are available in a variety of languages, formats, and sizes. Enjoy the experience.`;
  })();

  return (
    <WorkspaceInfoContainer>
      <WorkspaceInfoBackground />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          height: 1,
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <FitText base={45} min={16} step={4}>
          <Typography
            component="h1"
            color="primary"
            sx={{
              fontWeight: 'fontWeightBold',
              flexShrink: 0,
              lineHeight: 1.5,
            }}
          >
            {name}
          </Typography>
        </FitText>

        <WorkspaceInfoDescription flexShrink={1}>{description}</WorkspaceInfoDescription>
      </Box>
    </WorkspaceInfoContainer>
  );
};

export default DashboardWorkspaceInfo;
