import { PLUGIN_PROVIDERS } from '@monksflow/plugin-providers/web';
import { ChevronRight } from '@mui/icons-material';
import { Chip, Table, TableCell, Typography } from '@mui/material';
import { useWorkspaceCredentials } from 'api/platformApi/services/workspaceCredentialsService/hooks';
import MFTableBody from 'components/ui/MFTableBody/MFTableBody';
import MFTableHead from 'components/ui/MFTableHead/MFTableHead';
import MFTableHeadCell from 'components/ui/MFTableHeadCell/MFTableHeadCell';
import { ITableOrderDirection } from 'components/ui/MFTableHeadCell/MFTableHeadCell.types';
import MFTableHeadCellSortable from 'components/ui/MFTableHeadCellSortable/MFTableHeadCellSortable';
import MFTableHeadRow from 'components/ui/MFTableHeadRow/MFTableHeadRow';
import { useMemo, useState } from 'react';
import AddPluginCredentialDrawer from './AddWorkspaceCredentialDrawer/AddWorkspaceCredentialDrawer';
import { headCells } from './WorkspaceCredentials.consts';
import * as Styled from './WorkspaceCredentials.styles';
import { CredentialsTableSortableCells, ICredentialProvider } from './WorkspaceCredentials.types';
import { getComparator } from './WorkspaceCredentials.utils';

export interface WorkspaceCredentailsProps {
  workspaceId: string;
}

/** Renders the credentails  */
const WorkspaceCredentials: React.FC<WorkspaceCredentailsProps> = ({ workspaceId }) => {
  const [orderBy, setOrderBy] = useState<CredentialsTableSortableCells['id']>('name');
  const [orderDirection, setOrderDirection] = useState<ITableOrderDirection>('asc');
  const [isAddPluginCredentialOpen, setIsAddPluginCredentialOpen] = useState(false);
  const [provider, setProvider] = useState<string>('');

  const { data: credentials = [] } = useWorkspaceCredentials({ workspaceId });

  const credentialProviders: ICredentialProvider[] = useMemo(() => {
    return PLUGIN_PROVIDERS.map((provider) => {
      return {
        name: provider.name,
        provider: provider.provider,
        enabled: !!credentials.find((c) => c.provider === provider.provider),
      };
    });
  }, [credentials]);

  const sortedCredentials = useMemo(
    () => credentialProviders.toSorted(getComparator(orderDirection, orderBy)),
    [credentialProviders, orderDirection, orderBy],
  );

  return (
    <>
      <Styled.Container>
        <Styled.TitleHeader>
          <Typography component="h2" variant="titleMedium">
            APIs
          </Typography>
        </Styled.TitleHeader>
        <Typography
          variant="bodyMedium"
          sx={{
            color: 'text.primary',
          }}
        >
          API services accessed by applications and neurons in this workspace will appear here.
          Connect to the proper credentials to use for projects in this workspace.
        </Typography>
        <AddPluginCredentialDrawer
          open={isAddPluginCredentialOpen}
          onClose={() => {
            setIsAddPluginCredentialOpen(false);
          }}
          provider={provider}
          workspaceId={workspaceId}
        />
        <Table sx={{ mt: 2 }}>
          <MFTableHead>
            <MFTableHeadRow>
              {headCells.map((headCell) =>
                headCell.sortable ? (
                  <MFTableHeadCellSortable
                    key={headCell.id}
                    onSort={() => {
                      setOrderBy(headCell.id);
                      setOrderDirection((orderDirection) =>
                        orderDirection === 'desc' ? 'asc' : 'desc',
                      );
                    }}
                    active
                    direction={orderDirection}
                  >
                    {headCell.label}
                  </MFTableHeadCellSortable>
                ) : (
                  <MFTableHeadCell key={headCell.id}>{headCell.label}</MFTableHeadCell>
                ),
              )}
            </MFTableHeadRow>
          </MFTableHead>

          <MFTableBody>
            {sortedCredentials.map((credential) => (
              <Styled.CredentialRow
                key={credential.provider}
                onClick={() => {
                  setProvider(credential.provider);
                  setIsAddPluginCredentialOpen(true);
                }}
              >
                <TableCell>
                  <Typography variant="bodyMedium">{credential.name}</Typography>
                </TableCell>

                <TableCell>
                  <Chip
                    label={credential.enabled ? 'Connected' : 'Disconnected'}
                    color={credential.enabled ? 'success' : 'warning'}
                    size="small"
                  />
                </TableCell>

                <Styled.IconCell align="right">
                  <ChevronRight />
                </Styled.IconCell>
              </Styled.CredentialRow>
            ))}
          </MFTableBody>
        </Table>
      </Styled.Container>
    </>
  );
};

export default WorkspaceCredentials;
