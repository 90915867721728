import ErrorIcon from '@mui/icons-material/Error';
import { Button, Checkbox, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import useAgreementsAcknowledgements from 'api/platformApi/services/agreementAcknowledgementsService/useAgreementAcknowledgements';
import useAgreement from 'api/platformApi/services/agreementService/useAgreement';
import { getUsersMeAgreementQueryKey } from 'api/platformApi/services/usersMeAgreementsService/useUsersMeAgreements';
import Loader from 'components/Loader/Loader';
import MFButtonLoader from 'components/ui/MFButtonLoader/MFButtonLoader';
import PageFeedback from 'components/ui/PageFeedback/PageFeedback';
import useAgreementsContext from 'contexts/agreements/useAgreementsContext';
import { useState } from 'react';
import * as Styled from './EulaAcceptancePage.styles';

const EulaAcceptancePage = () => {
  const queryClient = useQueryClient();
  const eulaStatus = useAgreementsContext();
  const [isEulaReadConfirmed, setIsEulaReadConfirmed] = useState(false);
  const agreementId = eulaStatus?.id ?? '';

  const eulaQuery = useAgreement({ agreementId });
  const eula = eulaQuery.data;

  const eulaAcceptanceMutation = useAgreementsAcknowledgements(
    { agreementId },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getUsersMeAgreementQueryKey(),
        });
      },
    },
  );

  const handleOnContinueClick = () => {
    eulaAcceptanceMutation.mutate();
  };

  if (eulaQuery.isLoading) {
    return <Loader />;
  }

  if (!eula?.content || eulaQuery.isError) {
    return (
      <Styled.Main>
        <PageFeedback
          title="Legal information failed to load"
          description="An error occurred and the legal information could not be loaded. Please try refreshing the page to access."
        />
      </Styled.Main>
    );
  }

  return (
    <Styled.Main>
      <Styled.Container>
        <Styled.Header>
          <Typography
            variant="headlineLargeMd"
            sx={{
              color: 'text.primary',
            }}
          >
            Legal information
          </Typography>
        </Styled.Header>

        <Styled.ScrollableContainer>
          <Styled.Content
            style={{
              opacity: eulaAcceptanceMutation.isPending ? 0.5 : 1,
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: eula.content,
              }}
            />
            <Styled.FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={isEulaReadConfirmed}
                  onChange={(e) => {
                    setIsEulaReadConfirmed(e.target.checked);
                  }}
                />
              }
              label={
                <Typography variant="bodyMedium">
                  I have read and accept the terms and conditions of the privacy policy
                </Typography>
              }
            />
          </Styled.Content>
        </Styled.ScrollableContainer>

        <Styled.Footer>
          {eulaAcceptanceMutation.isError && (
            <Styled.Alert severity="error" icon={<ErrorIcon />}>
              The legal information was not accepted due to an error that occurred. Please try
              again.
            </Styled.Alert>
          )}
          <Button
            size="large"
            onClick={handleOnContinueClick}
            type="button"
            variant="contained"
            color="primary"
            disabled={!isEulaReadConfirmed}
          >
            {eulaAcceptanceMutation.isPending ? <MFButtonLoader /> : `Continue`}
          </Button>
        </Styled.Footer>
      </Styled.Container>
    </Styled.Main>
  );
};

export default EulaAcceptancePage;
