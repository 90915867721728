import { Close, Delete } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { IWorkspaceCredential } from 'api/platformApi/platformApi.types';
import { useRevokeWorkspacePluginCredential } from 'api/platformApi/services/workspaceCredentialsService/hooks';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { formatDistanceToNow } from 'date-fns';
import { useState } from 'react';
import * as Styled from './AddWorkspaceCredentialDrawer.styles';

interface WorkspaceCredentialCard extends IWorkspaceCredential {
  workspaceId: string;
  onDeleteSuccess: () => void;
}

const WorkspaceCredentialCard = ({
  id,
  name,
  workspaceId,
  createdAt,
  createdByUser,
  onDeleteSuccess,
}: WorkspaceCredentialCard) => {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const snackbar = useSnackbarContext();

  const { mutate, isPending } = useRevokeWorkspacePluginCredential({
    onSuccess: () => {
      onDeleteSuccess();
      snackbar.success('Key removed successfully');
    },
    onError() {
      snackbar.error('An error occurred and the credential could not be removed successfully.');
    },
  });

  return (
    <Styled.CredentialRow key={id}>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          opacity: showConfirmation ? 0.7 : 1,
        }}
      >
        <div>
          <Typography variant="body1">{name}</Typography>
          <Typography
            variant="bodySmall"
            sx={{
              color: 'text.secondary',
            }}
          >
            Added {formatDistanceToNow(createdAt, { addSuffix: true })}
          </Typography>
          <Typography
            component="p"
            variant="bodySmall"
            sx={{
              color: 'text.secondary',
            }}
          >
            Created by {createdByUser.name}
          </Typography>
        </div>
        <Tooltip title="Delete">
          <IconButton
            color="error"
            disabled={showConfirmation}
            onClick={() => {
              setShowConfirmation(true);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Stack>
      {showConfirmation && (
        <Alert
          severity="error"
          sx={{ mt: 1 }}
          action={
            <Box>
              <Button
                variant="text"
                color="error"
                disabled={isPending}
                onClick={() => {
                  mutate({ workspaceId, credentialId: id });
                }}
              >
                {isPending ? <CircularProgress size={18} color="error" /> : 'Delete'}
              </Button>
              <IconButton
                color="error"
                disabled={isPending}
                onClick={() => {
                  setShowConfirmation(false);
                }}
              >
                <Close />
              </IconButton>
            </Box>
          }
        >
          <Typography variant="titleMedium" component="h3">
            Delete Key?
          </Typography>
          <Typography variant="bodyMedium">
            Pipelines or features that require this app
            <br /> may fail if this API becomes disconnected.
          </Typography>
        </Alert>
      )}
    </Styled.CredentialRow>
  );
};

export default WorkspaceCredentialCard;
