import { Box, Typography } from '@mui/material';
import { IWorkspaceSolution } from 'api/platformApi/platformApi.types';
import useApps from 'api/platformApi/services/appsService/useApps';
import useWorkspaceSolutions from 'api/platformApi/services/workspaceSolutionsService/useWorkspaceSolutions';
import { useCallback } from 'react';
import createOpenSolutionUrl from 'utils/openSolutionUrl';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import SolutionsGrid from './SolutionsGrid/SolutionsGrid';

const DashboardSolutions = () => {
  const activeWorkspace = useActiveWorkspace();
  const workspaceId = activeWorkspace?.id;
  const workspaceSlug = activeWorkspace?.slug;

  const solutionsQuery = useWorkspaceSolutions({ workspaceId });
  const { data: solutions = [] } = solutionsQuery;

  const appsQuery = useApps();
  const apps = appsQuery.data?.data ?? [];
  const pathwaysApp = apps.find((app) => app.slug === 'pathways');

  const handleOpenSolution = useCallback(
    (solution: IWorkspaceSolution) => {
      const pipelineId = solution.pipelineId;
      if (!pathwaysApp || !workspaceSlug || !pipelineId) {
        return;
      }
      const url = createOpenSolutionUrl(pathwaysApp, {
        workspaceSlug,
        pipelineId,
      });
      if (!url) return;
      window.open(url, '_blank');
    },
    [pathwaysApp, workspaceSlug],
  );

  if (solutionsQuery.isSuccess && solutions.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="titleMedium">Solutions</Typography>
      </Box>

      <SolutionsGrid
        solutions={solutions}
        isError={solutionsQuery.isError}
        isLoading={solutionsQuery.isLoading}
        onSolutionClick={handleOpenSolution}
        onRetry={solutionsQuery.refetch}
      />
    </Box>
  );
};

export default DashboardSolutions;
