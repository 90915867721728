import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { forwardRef, useReducer } from 'react';

type PasswordTextFieldProps = TextFieldProps;

const PasswordTextField = forwardRef<HTMLInputElement, PasswordTextFieldProps>((props, ref) => {
  const [show, toggle] = useReducer((state) => !state, false);

  return (
    <TextField
      {...props}
      ref={ref}
      type={show ? 'text' : 'password'}
      slotProps={{
        input: {
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                edge="end"
                onClick={toggle}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
                size="small"
              >
                {show ? (
                  <VisibilityOffOutlined fontSize="medium" />
                ) : (
                  <VisibilityOutlined fontSize="medium" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  );
});

PasswordTextField.displayName = 'PasswordTextField';

export default PasswordTextField;
