import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  IWorkspaceUser,
  IWorkspaceUsersServiceParamsPath,
  IWorkspaceUsersServiceParamsQuery,
  IWorkspaceUsersServiceResponse,
} from 'api/platformApi/platformApi.types';
import { getWorkspaceUsers } from './workspaceUsersService';

export interface UseWorkspaceUsersParams
  extends Partial<IWorkspaceUsersServiceParamsPath>,
    IWorkspaceUsersServiceParamsQuery {
  enabled?: boolean;
  select?: (data: IWorkspaceUsersServiceResponse['data']) => IWorkspaceUsersServiceResponse['data'];
}

export type UseWorkspaceUsersOptions = Pick<UseQueryOptions<IWorkspaceUser[]>, 'enabled'>;

export const WORKSPACE_USERS_QUERY_KEY_PREFIX = ['workspaces', 'users'];

export const getWorkspaceUsersQueryKey = (params: UseWorkspaceUsersParams) => [
  ...WORKSPACE_USERS_QUERY_KEY_PREFIX,
  params.workspaceId,
  { name: params.name },
];

const useWorkspaceUsers = (params: UseWorkspaceUsersParams, options?: UseWorkspaceUsersOptions) => {
  const { workspaceId, name } = params;

  return useQuery({
    ...options,
    queryKey: getWorkspaceUsersQueryKey(params),
    queryFn: workspaceId
      ? () =>
          getWorkspaceUsers({
            params: { path: { workspaceId }, query: { name } },
          })
      : skipToken,
  });
};

export default useWorkspaceUsers;
