import { skipToken, useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';
import {
  ICreateWorkspaceCredentialsRequestBody,
  ICreateWorkspaceCredentialsResponse,
  IPlatformApiGenericError,
  IWorkspaceCredentailsServiceParamsPath,
  IWorkspaceServiceParamsPath,
} from 'api/platformApi/platformApi.types';
import { AxiosError } from 'axios';
import {
  createWorkspaceCredential,
  getWorkspaceCredentials,
  revokeWorkspacePluginCredential,
} from './services';

export type UseWorkspaceCredentialsParams = Partial<IWorkspaceServiceParamsPath>;

export const getWorkspaceCredentialsQueryKey = (params: UseWorkspaceCredentialsParams) => [
  'workspaces',
  params.workspaceId,
  'credentials',
];

export const useWorkspaceCredentials = (params: UseWorkspaceCredentialsParams) => {
  const { workspaceId } = params;

  return useQuery({
    queryKey: getWorkspaceCredentialsQueryKey(params),
    queryFn: workspaceId
      ? () => getWorkspaceCredentials({ params: { path: { workspaceId } } })
      : skipToken,
  });
};

type UseCreateWorkspaceCredentialOptions = UseMutationOptions<
  ICreateWorkspaceCredentialsResponse,
  AxiosError<IPlatformApiGenericError>,
  IWorkspaceCredentailsServiceParamsPath & ICreateWorkspaceCredentialsRequestBody
>;

export const useCreateWorkspaceCredential = (options: UseCreateWorkspaceCredentialOptions) => {
  return useMutation({
    ...options,
    mutationKey: ['createWorkspaceCredential'],
    mutationFn({ workspaceId, ...body }) {
      return createWorkspaceCredential({ params: { path: { workspaceId } }, body });
    },
  });
};

export const useRevokeWorkspacePluginCredential = (
  options: UseMutationOptions<
    void,
    AxiosError<IPlatformApiGenericError>,
    { workspaceId: string; credentialId: string }
  >,
) => {
  return useMutation({
    ...options,
    mutationKey: ['revokeWorkspaceCredential'],
    mutationFn: ({ workspaceId, credentialId }) => {
      return revokeWorkspacePluginCredential({
        params: { path: { workspaceId, credentialId } },
      });
    },
  });
};
