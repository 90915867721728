import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Box, Button, IconButton, InputAdornment, Tooltip } from '@mui/material';
import { useWorkspace } from 'api/platformApi/services/workspacesService/hooks';
import CreateProjectDrawer from 'components/CreateProjectDrawer/CreateProjectDrawer';
import { ChangeEvent, useState } from 'react';
import { useDebounce } from 'utils/debounce';
import { useOrganizationPreferences } from 'utils/preferences/useOrganizationPreferences';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import * as Styled from './ProjectsPanel.styles';
import { ProjectsPanelSortBy } from './ProjectsPanel.types';
import ProjectsView from './components/ProjectsView/ProjectsView';
import ProjectsViewToggle from './components/ProjectsViewToggle/ProjectsViewToggle';
import SortMenu from './components/SortMenu/SortMenu';

const ProjectsPanel = () => {
  const activeWorkspace = useActiveWorkspace();
  const { data: workspace } = useWorkspace({ workspaceId: activeWorkspace?.id });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const debouncedSearch = useDebounce(searchInputValue, 500);
  const validatedSearch = debouncedSearch.length > 2 ? debouncedSearch : '';

  const { preferences, save } = useOrganizationPreferences();
  const projectsSort = {
    property: preferences.projectsSortBy,
    direction: preferences.projectsSortDirection,
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target.value);
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleSortProjects = (sortBy: ProjectsPanelSortBy) => {
    if (sortBy === preferences.projectsSortBy) {
      const sortDirection = preferences.projectsSortDirection === 'asc' ? 'desc' : 'asc';

      save(
        {
          projectsSortBy: sortBy,
          projectsSortDirection: sortDirection,
        },
        true,
      );
    } else {
      save(
        {
          projectsSortBy: sortBy,
          projectsSortDirection: 'asc',
        },
        true,
      );
    }
  };

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Controls>
          <Styled.SearchInput
            value={searchInputValue}
            onChange={handleSearch}
            placeholder="Search..."
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                ),
                endAdornment: searchInputValue.length > 0 && (
                  <InputAdornment position="end">
                    <Tooltip title="Clear" placement="left">
                      <IconButton
                        onClick={() => {
                          setSearchInputValue('');
                        }}
                      >
                        <CloseOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
                disableUnderline: true,
              },
            }}
          />

          {preferences.projectsViewMode === 'list' && (
            <SortMenu
              projectsSort={projectsSort}
              onSortChange={(sortConfig) => {
                save(
                  {
                    projectsSortBy: sortConfig.property,
                    projectsSortDirection: sortConfig.direction,
                  },
                  true,
                );
              }}
            />
          )}
        </Styled.Controls>

        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
          }}
        >
          <ProjectsViewToggle
            onViewModeChange={(mode) => {
              save({ projectsViewMode: mode }, true);
            }}
            viewMode={preferences.projectsViewMode}
          />

          {workspace?.accessLevel === 'write' && (
            <Button variant="contained" color="black" onClick={handleDrawerOpen}>
              Create new project
            </Button>
          )}
        </Box>
      </Styled.Header>

      <CreateProjectDrawer open={isDrawerOpen} onClose={handleDrawerClose} />

      <ProjectsView
        onSort={handleSortProjects}
        searchValue={validatedSearch}
        sortBy={preferences.projectsSortBy}
        sortDirection={preferences.projectsSortDirection}
        viewMode={preferences.projectsViewMode}
      />
    </Styled.Container>
  );
};

export default ProjectsPanel;
