import { Box, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import useWorkspacePipelines from 'api/pathways/pipelines/hooks';
import { PathwaysPipeline } from 'api/pathways/pipelines/types';
import useUpdateWorkspaceSolutions from 'api/platformApi/services/updateWorkspaceSolutionsService/useUpdateWorkspaceSolutions';
import { getWorkspaceSolutionsQueryKey } from 'api/platformApi/services/workspaceSolutionsService/useWorkspaceSolutions';
import AppDrawer from 'components/AppDrawer/AppDrawer';
import AppDrawerContent from 'components/AppDrawer/components/AppDrawerContent/AppDrawerContent';
import AppDrawerHeader from 'components/AppDrawer/components/AppDrawerHeader/AppDrawerHeader';
import AppDrawerSubHeader from 'components/AppDrawer/components/AppDrawerSubHeader/AppDrawerSubHeader';
import CheckboxField from 'components/ui/CheckboxField/CheckboxField';
import MFButtonLoader from 'components/ui/MFButtonLoader/MFButtonLoader';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as Styled from './EditWorkspaceSolutionsDrawer.styles';
import {
  EditWorkspaceSolutionsDrawerProps,
  EditWorkspaceSolutionsForm,
} from './EditWorkspaceSolutionsDrawer.types';
import PipelineStatesWrapper from './components/PipelineStatesWrapper/PipelineStatesWrapper';

const EditWorkspaceSolutionsDrawer: FC<EditWorkspaceSolutionsDrawerProps> = (props) => {
  const { open, workspaceId, enabledSolutions } = props;
  const queryClient = useQueryClient();
  const snackbar = useSnackbarContext();

  const pipelinesQuery = useWorkspacePipelines({ workspaceId });
  const pipelines = pipelinesQuery.data ?? [];

  const checkedPipelines = pipelines.filter((pipeline) =>
    enabledSolutions.some((solution) => solution.pipelineId === pipeline.id),
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<EditWorkspaceSolutionsForm>({
    mode: 'all',
    defaultValues: {
      pipelines: [],
    },
    values: {
      pipelines: checkedPipelines,
    },
  });

  const handleClose = () => {
    reset();
    props.onClose();
  };

  const isSubmitDisabled =
    isSubmitting || pipelines.length === 0 || pipelinesQuery.isLoading || pipelinesQuery.isError;

  const updateSolutionMutation = useUpdateWorkspaceSolutions(
    { workspaceId },
    {
      onError: () => {
        snackbar.error(
          'An error occurred and the solutions could not be updated. Please try again.',
        );
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getWorkspaceSolutionsQueryKey({ workspaceId }),
        });
        handleClose();
        snackbar.success('Solutions updated successfully!');
      },
    },
  );

  const onEditSolutionsSubmit: SubmitHandler<EditWorkspaceSolutionsForm> = async (data) => {
    await updateSolutionMutation.mutateAsync({
      solutions: data.pipelines.map((pipeline) => ({
        name: pipeline.name,
        description: pipeline.description ?? '',
        pipelineId: pipeline.id,
      })),
    });
  };

  return (
    <AppDrawer open={open} onClose={handleClose} leftSidebar={false}>
      <Styled.Form onSubmit={handleSubmit(onEditSolutionsSubmit)}>
        <AppDrawerHeader title="Configure solutions" onClose={handleClose}></AppDrawerHeader>
        <AppDrawerSubHeader>
          <Typography
            variant="bodyMedium"
            sx={{
              color: 'text.primary',
            }}
          >
            Pipelines from this workspace will appear here. Select which pipelines should be
            accessible from the Solutions section of the workspace home:
          </Typography>
        </AppDrawerSubHeader>
        <AppDrawerContent>
          <PipelineStatesWrapper
            pipelines={pipelines}
            isLoading={pipelinesQuery.isLoading}
            isError={pipelinesQuery.isError}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
              }}
            >
              {pipelines.map((pipeline) => (
                <Controller
                  key={pipeline.id}
                  control={control}
                  name="pipelines"
                  render={({ field }) => (
                    <CheckboxField
                      label={pipeline.name}
                      checked={field.value.some((p: PathwaysPipeline) => p.id === pipeline.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          field.onChange([...field.value, pipeline]);
                        } else {
                          field.onChange(
                            field.value.filter((p: PathwaysPipeline) => p.id !== pipeline.id),
                          );
                        }
                      }}
                      size="small"
                      disabled={isSubmitting}
                    />
                  )}
                />
              ))}
            </Box>
          </PipelineStatesWrapper>
        </AppDrawerContent>

        <Styled.CustomAppDrawerFooter>
          <Styled.SaveChangesButton
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={isSubmitDisabled}
          >
            {isSubmitting ? <MFButtonLoader /> : `Save changes`}
          </Styled.SaveChangesButton>
        </Styled.CustomAppDrawerFooter>
      </Styled.Form>
    </AppDrawer>
  );
};

export default EditWorkspaceSolutionsDrawer;
