import platformApi from 'api/platformApi/platformApi';
import {
  ICreateWorkspaceCredentialsRequestBody,
  ICreateWorkspaceCredentialsResponse,
  IWorkspaceCredentailsServiceParamsPath,
  IWorkspaceCredentialsServiceResponse,
} from 'api/platformApi/platformApi.types';

export const getWorkspaceCredentials = async ({
  params: {
    path: { workspaceId },
  },
}: ServiceFnConfig<IWorkspaceCredentailsServiceParamsPath>) => {
  const axiosResponse = await platformApi.get<IWorkspaceCredentialsServiceResponse>(
    `/workspaces/${workspaceId}/credentials`,
  );
  return axiosResponse.data.data;
};

export const createWorkspaceCredential = async ({
  body,
  params: {
    path: { workspaceId },
  },
}: ServiceFnConfig<
  IWorkspaceCredentailsServiceParamsPath,
  undefined,
  ICreateWorkspaceCredentialsRequestBody
>) => {
  const axiosResponse = await platformApi.post<ICreateWorkspaceCredentialsResponse>(
    `/workspaces/${workspaceId}/credentials`,
    body,
  );

  return axiosResponse.data;
};

export const revokeWorkspacePluginCredential = async ({
  params: {
    path: { workspaceId, credentialId },
  },
}: ServiceFnConfig<IWorkspaceCredentailsServiceParamsPath & { credentialId: string }>) => {
  await platformApi.delete(`/workspaces/${workspaceId}/credentials/${credentialId}`);
};
