import { Box, Link, LinkProps, MenuItemProps } from '@mui/material';
import { forwardRef } from 'react';

type ContactSupportEmailProps = MenuItemProps & LinkProps & { onCopy: () => void };

const ContactSupportEmail = forwardRef<HTMLAnchorElement, ContactSupportEmailProps>(
  function ContactSupportEmail(props, ref) {
    const { onCopy, onClick, ...rest } = props;

    const handleCopy = () => {
      void navigator.clipboard.writeText('support@monksflow.ai');
      onCopy();
    };

    return (
      <Link
        color="text.primary"
        underline="none"
        {...rest}
        onClick={(e) => {
          handleCopy();
          onClick?.(e);
        }}
        ref={ref}
      >
        <Box mr={2}>
          {rest.children}
          Email support
        </Box>
      </Link>
    );
  },
);

export default ContactSupportEmail;
