import { Button } from '@mui/material';
import styled, { css } from 'styled-components';

export const TabButton = styled(Button)`
  ${({ theme, variant }) =>
    variant === 'text' &&
    css`
      color: ${theme.palette.text.secondary};
    `}
`;
