import { Button, Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { getOrganizationQueryKey } from 'api/platformApi/services/organizationService/useOrganization';
import useUpdateOrganizationApps from 'api/platformApi/services/updateOrganizationAppsService/useUpdateOrganizationApps';
import AppDrawer from 'components/AppDrawer/AppDrawer';
import AppDrawerContent from 'components/AppDrawer/components/AppDrawerContent/AppDrawerContent';
import AppDrawerFooter from 'components/AppDrawer/components/AppDrawerFooter/AppDrawerFooter';
import AppDrawerHeader from 'components/AppDrawer/components/AppDrawerHeader/AppDrawerHeader';
import SelectApplications from 'components/SelectApplications/SelectApplications';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  EditOrganizationAppsDrawerProps,
  EditOrganizationAppsFormFields,
} from './EditOrganizationAppsDrawer.types';

const EDIT_ORGANIZATION_APPS_FORM_ID = 'edit-organization-apps-form';

const EditOrganizationAppsDrawer: FC<EditOrganizationAppsDrawerProps> = ({
  open,
  onClose,
  enabledApps,
}) => {
  const { organizationId = '' } = useParams();
  const queryClient = useQueryClient();
  const snackbar = useSnackbarContext();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
  } = useForm<EditOrganizationAppsFormFields>({
    defaultValues: {
      applications: enabledApps,
    },
  });

  const updateOrganizationAppsMutation = useUpdateOrganizationApps(
    { organizationId: organizationId },
    {
      onError: () => {
        snackbar.error(
          'An error occurred and the organization apps could not be updated. Please try again.',
        );
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: getOrganizationQueryKey(organizationId) });

        reset({ applications: enabledApps });
        onClose();
        snackbar.success('Applications successfully saved! Organization updated');
      },
    },
  );

  const onEditOrganizationAppsSubmit: SubmitHandler<EditOrganizationAppsFormFields> = async (
    data,
  ) => {
    await updateOrganizationAppsMutation.mutateAsync({
      appIds: data.applications.map((app) => app.id),
    });
  };

  const isSubmitDisabled = !isValid || isSubmitting;

  useEffect(() => {
    reset({ applications: enabledApps });
  }, [enabledApps, reset]);

  return (
    <AppDrawer open={open} onClose={onClose}>
      <AppDrawerHeader title="Edit applications" onClose={onClose} />

      <AppDrawerContent>
        <form
          id={EDIT_ORGANIZATION_APPS_FORM_ID}
          onSubmit={handleSubmit(onEditOrganizationAppsSubmit)}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
              }}
            >
              <Controller
                name="applications"
                control={control}
                render={({ field }) => (
                  <SelectApplications
                    label="Applications"
                    value={field.value}
                    onChange={field.onChange}
                    disabled={isSubmitting}
                  />
                )}
              />
            </Box>
          </Box>
        </form>
      </AppDrawerContent>

      <AppDrawerFooter>
        <Box
          sx={{
            marginLeft: 'auto',
          }}
        >
          <Button
            disabled={isSubmitDisabled}
            form={EDIT_ORGANIZATION_APPS_FORM_ID}
            size="large"
            type="submit"
            variant="contained"
          >
            Save changes
          </Button>
        </Box>
      </AppDrawerFooter>
    </AppDrawer>
  );
};

export default EditOrganizationAppsDrawer;
