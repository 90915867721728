import { MenuItem, Typography } from '@mui/material';
import createIntentUrl from 'utils/intentUrl';
import * as Styled from './ToolsSubmenu.styles';
import { ToolsSubmenuProps } from './ToolsSubmenu.types';

const ToolsSubmenu: React.FC<ToolsSubmenuProps> = ({ tools, activeWorkspaceSlug, onItemClick }) => {
  return (
    <>
      <Styled.MenuLabel variant="labelMedium" color="text.secondary">
        Workspace Tools
      </Styled.MenuLabel>
      {tools.map((tool) => (
        <MenuItem key={tool.name} onClick={onItemClick}>
          <Styled.Link
            href={
              createIntentUrl(tool, 'openWorkspace', { workspaceSlug: activeWorkspaceSlug }) ?? ''
            }
            target="_blank"
          >
            <Styled.ToolIcon src={tool.logo} alt={tool.name} />
            <Typography
              variant="titleMedium"
              sx={{
                color: 'text.primary',
              }}
            >
              {tool.name}
            </Typography>
          </Styled.Link>
        </MenuItem>
      ))}
    </>
  );
};

export default ToolsSubmenu;
