import { Box, Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import useCreateOrganizationAdmin from 'api/platformApi/services/createOrganizationAdminService/useCreateOrganizationAdmin';
import { getOrganizationAdminsQueryKey } from 'api/platformApi/services/organizationAdminsService/useOrganizationAdmins';
import AppDrawer from 'components/AppDrawer/AppDrawer';
import AppDrawerContent from 'components/AppDrawer/components/AppDrawerContent/AppDrawerContent';
import AppDrawerFooter from 'components/AppDrawer/components/AppDrawerFooter/AppDrawerFooter';
import AppDrawerHeader from 'components/AppDrawer/components/AppDrawerHeader/AppDrawerHeader';
import MFButtonLoader from 'components/ui/MFButtonLoader/MFButtonLoader';
import RHFMonkTextField from 'components/ui/rhf-components/RHFMonkTextField/RHFMonkTextField';
import {
  emailValidation,
  requiredValidation,
} from 'components/ui/rhf-components/utils/validationRules';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  AddOrganizationAdminDrawerProps,
  AddOrganizationAdminFormFields,
} from './AddOrganizationAdminDrawer.types';

const ADD_ORGANIZATION_ADMIN_FORM_ID = 'add-organization-admin-form';
const DEFAULT_FORM_VALUES: AddOrganizationAdminFormFields = {
  email: '',
};

const AddOrganizationAdminDrawer: FC<AddOrganizationAdminDrawerProps> = ({ open, onClose }) => {
  const { organizationId = '' } = useParams();
  const queryClient = useQueryClient();
  const snackbar = useSnackbarContext();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    reset,
  } = useForm<AddOrganizationAdminFormFields>({
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const createOrganizationAdminMutation = useCreateOrganizationAdmin(
    { organizationId },
    {
      onError: (error) => {
        if (error.scope === 'field' && error.field) {
          setError(error.field, { message: error.message });
        }

        if (error.scope === 'snackbar') {
          snackbar.error(
            'An error occurred and the organization admin could not be invited. Please try again.',
          );
        }
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getOrganizationAdminsQueryKey({ organizationId }),
        });

        reset(DEFAULT_FORM_VALUES);
        onClose();
        snackbar.success('Organization admin invited');
      },
    },
  );

  const onCreateOrganizationAdminSubmit: SubmitHandler<AddOrganizationAdminFormFields> = async (
    data,
  ) => {
    await createOrganizationAdminMutation.mutateAsync({
      email: data.email,
    });
  };

  const isSubmitDisabled = !isValid || isSubmitting;

  return (
    <AppDrawer open={open} onClose={onClose}>
      <AppDrawerHeader title="Invite admin user" onClose={onClose} />

      <AppDrawerContent>
        <form
          id={ADD_ORGANIZATION_ADMIN_FORM_ID}
          onSubmit={handleSubmit(onCreateOrganizationAdminSubmit)}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
              }}
            >
              <RHFMonkTextField
                control={control}
                disabled={isSubmitting}
                label="Email address"
                name="email"
                placeholder="username@organization.com"
                rules={{
                  ...emailValidation,
                  ...requiredValidation,
                }}
                type="email"
                required
                fullWidth
              />
            </Box>
          </Box>
        </form>
      </AppDrawerContent>

      <AppDrawerFooter>
        <Box
          sx={{
            marginLeft: 'auto',
          }}
        >
          <Button
            disabled={isSubmitDisabled}
            form={ADD_ORGANIZATION_ADMIN_FORM_ID}
            size="large"
            type="submit"
            variant="contained"
          >
            {isSubmitting ? <MFButtonLoader /> : `Save changes`}
          </Button>
        </Box>
      </AppDrawerFooter>
    </AppDrawer>
  );
};

export default AddOrganizationAdminDrawer;
