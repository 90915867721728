import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { SearchInputProps } from './SearchInput.types';

const SearchInput = ({ value, onChange, placeholder }: SearchInputProps) => {
  const handleClearValue = () => {
    onChange('');
  };

  return (
    <TextField
      value={value}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      placeholder={placeholder}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon />
            </InputAdornment>
          ),
          endAdornment: value.length > 0 && (
            <InputAdornment position="end">
              <Tooltip title="Clear" placement="left">
                <IconButton onClick={handleClearValue}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
          disableUnderline: true,
        },
      }}
      sx={{
        width: '240px',
        '& .MuiInput-root': {
          marginTop: 0,
        },
      }}
    />
  );
};

export default SearchInput;
