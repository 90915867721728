import { Box, type Grid2Props } from '@mui/material';
import Grid from '@mui/material/Grid2/Grid2';
import { PropsWithChildren } from 'react';

export const GridContainer = ({ children, ...props }: PropsWithChildren<Grid2Props>) => {
  return (
    <Grid container {...props}>
      {children}
    </Grid>
  );
};

export const GridItem = ({ children, ...props }: PropsWithChildren<Grid2Props>) => {
  return (
    <Grid {...props}>
      <Box
        sx={{
          borderRadius: 4,
          height: 1,
          overflow: 'hidden',
        }}
      >
        {children}
      </Box>
    </Grid>
  );
};
