import { TableCell, Typography } from '@mui/material';
import { IInvitationStatus } from 'api/platformApi/platformApi.types';
import StatusChip from 'components/StatusChip/StatusChip';
import ConfirmDialog from 'components/ui/ConfirmDialog/ConfirmDialog';
import MFTableBodyRow from 'components/ui/MFTableBodyRow/MFTableBodyRow';
import { formatDate } from 'date-fns';
import { FC, useReducer } from 'react';
import { formatRelativeDate } from 'utils/dates';
import OrgAdminInvitationOptions from '../OrgAdminInvitationOptions/OrgAdminInvitationOptions';
import { OrgAdminInvitationRowProps } from './OrgAdminInvitationRow.types';

const OrgAdminInvitationRow: FC<OrgAdminInvitationRowProps> = ({
  admin,
  disabled,
  onClickResend,
  onClickRevoke,
}) => {
  const [revoking, toggleRevoking] = useReducer((state) => !state, false);
  const [processing, toggleProcessing] = useReducer((state) => !state, false);

  const handleResendInvitation = async () => {
    try {
      toggleProcessing();
      await onClickResend();
    } finally {
      toggleProcessing();
    }
  };

  const handleRevokeInvitation = async () => {
    try {
      toggleProcessing();
      await onClickRevoke();
    } finally {
      toggleRevoking();
      toggleProcessing();
    }
  };

  const isExpiredOrRevoked =
    admin.status === IInvitationStatus.REVOKED || admin.status === IInvitationStatus.EXPIRED;
  const isDisabled = disabled ?? (isExpiredOrRevoked || processing);

  return (
    <>
      <MFTableBodyRow
        key={admin.email}
        sx={[
          isDisabled
            ? {
                'pointer-events': 'none',
                opacity: 0.5,
              }
            : {
                'pointer-events': 'auto',
                opacity: 1,
              },
        ]}
      >
        <TableCell>
          <Typography variant="bodyMedium">{admin.email}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="bodyMedium">
            {admin.dateSent ? formatRelativeDate(admin.dateSent) : '-'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="bodyMedium">
            {admin.expiresAt ? formatDate(admin.expiresAt, 'MMMM d, yyyy') : '-'}
          </Typography>
        </TableCell>
        <TableCell>
          <StatusChip status={admin.status} />
        </TableCell>

        <TableCell>
          {admin.status === IInvitationStatus.PENDING && (
            <OrgAdminInvitationOptions
              onResendInvitation={handleResendInvitation}
              onRevokeInvitation={toggleRevoking}
            />
          )}
        </TableCell>
      </MFTableBodyRow>

      <ConfirmDialog
        disabled={isDisabled}
        id="revoke-invitation-dialog"
        label="Revoke"
        loading={processing}
        open={revoking}
        onCancel={toggleRevoking}
        onConfirm={handleRevokeInvitation}
        title="Revoke invitation?"
        content={
          <>
            Are you sure you want to revoke this user invitation?
            <br />
            This action cannot be undone.
          </>
        }
      />
    </>
  );
};

export default OrgAdminInvitationRow;
