import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import {
  DashboardVideoBackground,
  DashboardVideoContainer,
  DashboardVideoPlayButton,
  DashboardVideoPlayOverlay,
  Gradient,
} from './DashboardVideo.styles';

const DashboardVideo = ({
  gradient,
  label,
  link,
}: {
  gradient: Gradient;
  label: ReactNode;
  link: string;
}) => {
  return (
    <DashboardVideoContainer>
      <DashboardVideoBackground $gradient={gradient} />

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          marginTop: 'auto',
        }}
      >
        <Typography
          sx={{
            color: 'text.primary',
            fontSize: 20,
            fontWeight: 'fontWeightMedium',
            lineHeight: 1,
          }}
        >
          {label}
        </Typography>
      </Box>

      <DashboardVideoPlayOverlay href={link}>
        <DashboardVideoPlayButton />
      </DashboardVideoPlayOverlay>
    </DashboardVideoContainer>
  );
};

export default DashboardVideo;
