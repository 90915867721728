import { EditOutlined } from '@mui/icons-material';
import { Button, Table, TableCell, Typography } from '@mui/material';
import useWorkspaceSolutions from 'api/platformApi/services/workspaceSolutionsService/useWorkspaceSolutions';
import MFTableBody from 'components/ui/MFTableBody/MFTableBody';
import MFTableBodyRow from 'components/ui/MFTableBodyRow/MFTableBodyRow';
import MFTableHead from 'components/ui/MFTableHead/MFTableHead';
import MFTableHeadCell from 'components/ui/MFTableHeadCell/MFTableHeadCell';
import MFTableHeadRow from 'components/ui/MFTableHeadRow/MFTableHeadRow';
import { useReducer } from 'react';
import EditWorkspaceSolutionsDrawer from './EditWorkspaceSolutionsDrawer/EditWorkspaceSolutionsDrawer';
import { HEAD_CELLS_SX, headCells } from './WorkspaceSolutions.consts';
import * as Styled from './WorkspaceSolutions.styles';
import { WorkspaceSolutionsProps } from './WorkspaceSolutions.types';

const WorkspaceSolutions: React.FC<WorkspaceSolutionsProps> = ({ workspaceId }) => {
  const { data: solutions = [] } = useWorkspaceSolutions({ workspaceId });

  const [isEditDrawerOpen, toggleEditDialog] = useReducer((state) => !state, false);

  return (
    <>
      <Styled.Container>
        <Styled.TitleHeader>
          <Typography component="h2" variant="titleMedium">
            Solutions {solutions.length > 0 && `(${solutions.length})`}
          </Typography>

          <Button
            onClick={toggleEditDialog}
            variant="outlined"
            size="small"
            startIcon={<EditOutlined />}
          >
            Edit
          </Button>
        </Styled.TitleHeader>

        {solutions.length === 0 && (
          <Styled.NoSolutionsContainer>
            <Typography
              variant="bodyMedium"
              sx={{
                color: 'text.secondary',
                textAlign: 'center',
              }}
            >
              No solutions added to this workspace. Add pipelines from the workspace to this space
              for easy access and reuse.
            </Typography>
          </Styled.NoSolutionsContainer>
        )}

        {solutions.length > 0 && (
          <Table>
            <MFTableHead>
              <MFTableHeadRow>
                {headCells.map((cell) => (
                  <MFTableHeadCell key={cell.id} sx={HEAD_CELLS_SX}>
                    {cell.label}
                  </MFTableHeadCell>
                ))}
              </MFTableHeadRow>
            </MFTableHead>
            <MFTableBody>
              {solutions.map((solution) => (
                <MFTableBodyRow key={solution.id}>
                  <TableCell>{solution.name}</TableCell>
                </MFTableBodyRow>
              ))}
            </MFTableBody>
          </Table>
        )}
      </Styled.Container>

      <EditWorkspaceSolutionsDrawer
        open={isEditDrawerOpen}
        onClose={toggleEditDialog}
        workspaceId={workspaceId}
        enabledSolutions={solutions}
      />
    </>
  );
};

export default WorkspaceSolutions;
