import { TableCell } from '@mui/material';
import MFTableBodyRow from 'components/ui/MFTableBodyRow/MFTableBodyRow';
import styled from 'styled-components';

export const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.surface.level1};
  border-radius: ${({ theme }) => theme.spacing(2)};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.02);
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const IconCell = styled(TableCell)`
  display: flex;
  align-items: center;
`;

export const CredentialRow = styled(MFTableBodyRow)`
  cursor: pointer;
`;
