import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import { Button, Typography } from '@mui/material';
import { IUpdateBookmark } from 'api/platformApi/platformApi.types';
import useUpdateWorkspaceProjectBookmarks from 'api/platformApi/services/updateWorkspaceProjectBookmarksService/useUpdateWorkspaceProjectBookmarks';
import MFButtonLoader from 'components/ui/MFButtonLoader/MFButtonLoader';
import RHFMonkTextField from 'components/ui/rhf-components/RHFMonkTextField/RHFMonkTextField';
import {
  getMaxLengthValidation,
  requiredValidation,
  URLValidation,
} from 'components/ui/rhf-components/utils/validationRules';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import * as Styled from './AddBookmark.styles';
import { AddBookmarkProps, IAddBookmarkForm } from './AddBookmark.types';

const AddBookmark: FC<AddBookmarkProps> = (props) => {
  const { currentBookmarks } = props;

  const snackbar = useSnackbarContext();
  const params = useParams();
  const activeWorkspace = useActiveWorkspace();
  const workspaceId = activeWorkspace?.id ?? '';
  const projectId = params.projectId ?? '';

  const [addBookmarkElement, setAddBookmarkElement] = useState<null | HTMLButtonElement>(null);
  const isPopoverOpen = Boolean(addBookmarkElement);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAddBookmarkElement(event.currentTarget);
  };

  const handlePopoverClose = () => {
    reset();
    setAddBookmarkElement(null);
  };

  const updateWorkspaceProjectBookmarksMutation = useUpdateWorkspaceProjectBookmarks(
    workspaceId,
    projectId,
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
  } = useForm<IAddBookmarkForm>({
    mode: 'all',
    defaultValues: {
      title: '',
      url: '',
    },
  });

  const onAddBookmarkSubmit: SubmitHandler<IAddBookmarkForm> = async (data) => {
    const updatedBookmarks: IUpdateBookmark[] = [...currentBookmarks];
    updatedBookmarks.push({ name: data.title, url: data.url });

    await updateWorkspaceProjectBookmarksMutation.mutateAsync(
      { bookmarks: updatedBookmarks },
      {
        onSuccess: () => {
          handlePopoverClose();
          snackbar.success('Bookmark added!');
        },
      },
    );
  };

  const isSubmitDisabled = !isValid || isSubmitting;

  return (
    <>
      <Styled.AddBookmarkButton
        onClick={handlePopoverOpen}
        startIcon={<AddCircleOutlineIcon />}
        variant="text"
        color="black"
        size="small"
      >
        Add bookmark
      </Styled.AddBookmarkButton>
      <Styled.Popover
        open={isPopoverOpen}
        anchorEl={addBookmarkElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
      >
        <Styled.Form onSubmit={handleSubmit(onAddBookmarkSubmit)}>
          {updateWorkspaceProjectBookmarksMutation.isError && (
            <Styled.Alert severity="error" icon={<ErrorIcon />}>
              An error occurred and the bookmark was not saved.
            </Styled.Alert>
          )}

          <Typography
            variant="titleMedium"
            sx={{
              color: 'text.primary',
            }}
          >
            Add Bookmark
          </Typography>

          <RHFMonkTextField
            name="title"
            control={control}
            rules={{ ...requiredValidation, ...getMaxLengthValidation(50) }}
            label="Bookmark Title"
            disabled={isSubmitting}
            required
            fullWidth
          />

          <RHFMonkTextField
            name="url"
            control={control}
            rules={{ ...requiredValidation, ...URLValidation }}
            label="URL"
            disabled={isSubmitting}
            required
            fullWidth
          />

          <Styled.Footer>
            <Button onClick={handlePopoverClose} variant="text" color="black" size="medium">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isSubmitDisabled}
              variant="contained"
              color="primary"
              size="medium"
            >
              {isSubmitting ? <MFButtonLoader /> : `Add Bookmark`}
            </Button>
          </Styled.Footer>
        </Styled.Form>
      </Styled.Popover>
    </>
  );
};

export default AddBookmark;
