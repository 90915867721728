import { Box, Typography } from '@mui/material';
import { useWorkspaceMetrics } from 'api/platformApi/services/workspaceMetricsService/hooks';
import { animate } from 'motion';
import { ReactNode, useEffect, useRef } from 'react';
import { pluralize } from 'utils/i18n';
import { useActiveWorkspace } from 'utils/useActiveWorkspace';
import { DashboardStatBackground, DashboardStatContainer } from './DashboardStat.styles';

const DashboardStat = ({ label, stat }: { label: ReactNode; stat: number }) => {
  const statRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const { current: statElement } = statRef;

    if (!statElement || stat === 0) return;

    const target = stat;
    const initial = 0;

    animate(
      (progress) =>
        (statElement.textContent = Math.floor(initial + (target - initial) * progress).toString()),
      { easing: 'ease', duration: 0.2 },
    );
  }, [stat]);

  return (
    <DashboardStatContainer>
      <DashboardStatBackground />

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          marginTop: 'auto',
        }}
      >
        <Typography
          color="primary"
          ref={statRef}
          sx={{
            fontSize: 60,
            fontWeight: 'fontWeightExtraBold',
            lineHeight: 1,
          }}
        >
          {stat}
        </Typography>

        <Typography
          color="primary"
          sx={{
            fontSize: 22,
            fontWeight: 'fontWeightMedium',
            lineHeight: 1,
          }}
        >
          {label}
        </Typography>
      </Box>
    </DashboardStatContainer>
  );
};

export default DashboardStat;

export const DashboardWorkspaceProjectsStat = () => {
  const workspace = useActiveWorkspace();
  const { data: metrics } = useWorkspaceMetrics({ workspaceId: workspace?.id });
  const { totalProjects = 0 } = metrics ?? {};

  return <DashboardStat label="Total of Projects" stat={totalProjects} />;
};

export const DashboardWorkspaceMembersStat = () => {
  const workspace = useActiveWorkspace();
  const { data: metrics } = useWorkspaceMetrics({ workspaceId: workspace?.id });
  const { totalMembers = 0 } = metrics ?? {};

  return <DashboardStat label={pluralize(totalMembers, 'Member', 'Members')} stat={totalMembers} />;
};
