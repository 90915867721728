/* eslint-disable @typescript-eslint/no-empty-interface */

/**************************************************************************************************
 * GENERIC
 **************************************************************************************************/

export type IAccessLevel = 'read' | 'write';

export interface IPlatformApiGenericError {
  error: string;
  message: string | string[];
  statusCode: number;
}

export interface IPlatformApiListResponse<Data> {
  data: Data[];
}

export type IPlatformApiResourceResponse<Data> = Data;

export interface IPlatformApiPaginatedResponse {
  currentCursor: string;
  nextCursor: string;
}

export interface IPlatformApiPaginatedParamsQuery {
  nextCursor?: string;
  limit?: number;
}

export interface IPlatformApiOffsetPaginatedResponse {
  pagination: {
    pagesCount: number;
    resultsCount: number;
    nextPageNumber: number | undefined;
    pageNumber: number;
    pageSize: number;
  };
}

export interface IPlatformApiOffsetPaginatedParamsQuery {
  pageNumber?: number;
  pageSize?: number;
}

/**************************************************************************************************
 * ORGANIZATIONS
 **************************************************************************************************/

export type IOrganizationAdmin = Pick<IUser, 'id' | 'email' | 'name'>;

export interface IOrganization {
  id: string;
  name: string;
  logoUrl: string | null;
  slug: string;
  apps: IApp[];
  admins: IOrganizationAdmin[];
  createdAt: string;
  updatedAt: string;
}

/**************************************************************************************************
 * GET /organizations
 **************************************************************************************************/

export interface IOrganizationsServiceResponse {
  data: IOrganization[];
}

/**************************************************************************************************
 * POST /organizations
 **************************************************************************************************/

export interface ICreateOrganizationServiceRequestBody {
  name: string;
  administratorEmail: string;
  authMethod: {
    clientId: string;
    clientSecret: string;
    issuerUrl: string;
    methodName: string;
  };
  logoUrl?: string;
  appIds?: string[];
}

export interface ICreateOrganizationServiceResponse extends IOrganization {}

/**************************************************************************************************
 * PUT /organizations/{organizationId}
 **************************************************************************************************/

export interface IUpdateOrganizationRequestBody {
  name: string;
  logoUrl: string | null;
}

export interface IUpdateOrganizationResponse extends IOrganization {}

/**************************************************************************************************
 * POST /organizations/{organizationId}/apps
 **************************************************************************************************/

export interface IUpdateOrganizationAppsServiceParamsPath {
  organizationId: string;
}

export interface IUpdateOrganizationAppsServiceRequestBody {
  appIds: string[];
}

export interface IUpdateOrganizationAppsServiceResponse {
  data: IApp[];
}

/**************************************************************************************************
 * POST /organizations/{organizationId}/invitations
 **************************************************************************************************/

export interface ICreateOrganizationAdminServiceParamsPath {
  organizationId: string;
}

export interface ICreateOrganizationAdminServiceRequestBody {
  email: string;
}

export interface ICreateOrganizationAdminServiceResponse extends IInvitation {}

/**************************************************************************************************
 * POST /organizations/{organizationId}/invitations/{invitationId}/actions/resend
 **************************************************************************************************/

export interface IResendOrganizationAdminInvitationServiceParamsPath {
  organizationId: string;
  invitationId: string;
}

export interface IResendOrganizationAdminInvitationResponse extends IInvitation {}

/**************************************************************************************************
 * POST /organizations/{organizationId}/invitations/{invitationId}/actions/revoke
 **************************************************************************************************/

export interface IRevokeOrganizationInvitationServiceParamsPath {
  organizationId: string;
  invitationId: string;
}

export interface IRevokeOrganizationInvitationResponse extends IInvitation {}

/**************************************************************************************************
 * GET /organizations/{organizationId}/sso-methods
 **************************************************************************************************/

export interface IOrganizationAuthMethod {
  id: string;
  name: string;
  clientId: string;
  issuerUrl: string;
}

export interface IOrganizationAuthMethodWithClientSecret extends IOrganizationAuthMethod {
  clientSecret: string;
}

export interface IOrganizationAuthMethodsServiceResponse {
  data: IOrganizationAuthMethod[];
}

/**************************************************************************************************
 * POST /organizations/{organizationId}/sso-methods
 **************************************************************************************************/

export interface ICreateOrganizationAuthServiceParamsPath {
  organizationId: string;
  methodId: string;
}

export interface ICreateOrganizationAuthMethodRequestBody {
  methodName: string;
  issuerUrl: string;
  clientId: string;
  clientSecret: string;
}

export interface ICreateOrganizationAuthMethodResponse extends IOrganizationAuthMethod {}

/**************************************************************************************************
 * GET /organizations/{organizationId}/sso-methods/{methodId}
 **************************************************************************************************/

export interface IOrganizationAuthMethodServiceParamsPath {
  organizationId: string;
  methodId: string;
}

export interface IOrganizationAuthMethodResponse extends IOrganizationAuthMethodWithClientSecret {}

/**************************************************************************************************
 * PUT /organizations/{organizationId}/sso-methods/{methodId}
 **************************************************************************************************/

export interface IUpdateOrganizationAuthMethodServiceParamsPath {
  organizationId: string;
  methodId: string;
}

export interface IUpdateOrganizationAuthMethodRequestBody
  extends ICreateOrganizationAuthMethodRequestBody {}

export interface IUpdateOrganizationAuthMethodResponse extends IOrganizationAuthMethod {}

/**************************************************************************************************
 * DELETE /organizations/{organizationId}/sso-methods/{methodId}
 **************************************************************************************************/

export interface IDeleteOrganizationAuthMethodServiceParamsPath {
  organizationId: string;
  methodId: string;
}

/**************************************************************************************************
 * GET /organizations/{organizationId}/admins
 **************************************************************************************************/

export interface IOrganizationAdminInvitation extends IInvitation {}

export interface IOrganizationAdminsServiceParamsPath {
  organizationId: string;
}

export interface IOrganizationAdminsServiceResponse
  extends IPlatformApiListResponse<IOrganizationAdminInvitation> {}

/**************************************************************************************************
 * PERMISSIONS
 **************************************************************************************************/

export interface IPermission {
  name: string;
  key: string;
  description: string;
}

/**************************************************************************************************
 * GET /permissions
 **************************************************************************************************/

export interface IPermissionsServiceResponse extends IPlatformApiListResponse<IPermission> {}

/**************************************************************************************************
 * WORKSPACES
 **************************************************************************************************/

export interface IWorkspace {
  id: string;
  organizationId: string;
  name: string;
  description: string | null;
  slug: string;
  logoUrl: string | null;
  createdAt: string;
  updatedAt: string;
  accessLevel: IAccessLevel;
}

export interface IWorkspaceProjectBookmark {
  name: string;
  url: string;
  logoUrl: string | null;
}

export interface IWorkspaceProject {
  id: string;
  owner: {
    id: string;
    name: string;
    email: string;
  };
  workspaceId: string;
  name: string;
  description: string | null;
  bookmarks: IWorkspaceProjectBookmark[];
  visibility: 'workspace' | 'private';
  createdAt: string;
  updatedAt: string;
  accessLevel: IAccessLevel;
}

export interface IActionByUser {
  id: string;
  name: string;
  email: string;
}

export interface IProjectFile {
  id: string;
  name: string;
  description: string | null;
  type: string;
  fileType: {
    name: string;
    intents: Record<string, string | undefined>;
  };
  app: {
    id: string;
    name: string;
    logo: string | null;
    hostedUrl: string;
  };
  createdBy: IActionByUser;
  createdAt: string;
  updatedBy: IActionByUser;
  updatedAt: string;
}

/**************************************************************************************************
 * POST /workspaces
 **************************************************************************************************/

export interface ICreateWorkspaceServiceRequestBody {
  name: string;
  description?: string | null;
  logoUrl?: string;
}

export interface ICreateWorkspaceServiceResponse extends IWorkspace {}

/**************************************************************************************************
 * GET /workspaces
 **************************************************************************************************/

export interface IWorkspacesServiceResponse {
  data: IWorkspace[];
}

/**************************************************************************************************
 * GET /workspaces/{workspaceId}
 **************************************************************************************************/

export interface IWorkspaceServiceResponse extends IWorkspace {}

export interface IWorkspaceServiceParamsPath {
  workspaceId: string;
}

/**************************************************************************************************
 * PUT /workspaces/{workspaceId}
 **************************************************************************************************/

export interface IUpdateWorkspaceServiceRequestBody {
  name: string;
  description?: string | null;
  logoUrl?: string | null;
}

export interface IUpdateWorkspaceServiceResponse extends IWorkspace {}

export interface IUpdateWorkspaceServiceParamsPath {
  workspaceId: string;
}

/**************************************************************************************************
 * GET /workspaces/{workspaceId}/metrics
 **************************************************************************************************/

export interface IWorkspaceMetricsServiceResponse {
  totalMembers: number;
  totalProjects: number;
}

export interface IWorkspaceMetricsServiceParamsPath {
  workspaceId: string;
}

/**************************************************************************************************
 * GET /workspaces/{workspaceId}/solutions
 **************************************************************************************************/

export interface IWorkspaceSolution {
  id: string;
  name: string;
  description: string;
  pipelineId: string;
}

export interface IWorkspaceSolutionsServiceResponse {
  data: IWorkspaceSolution[];
}

export interface IWorkspaceSolutionsServiceParamsPath {
  workspaceId: string;
}

/**************************************************************************************************
 * PUT /workspaces/{workspaceId}/solutions
 **************************************************************************************************/

export interface IUpdateWorkspaceSolutionsServiceParamsPath {
  workspaceId: string;
}

export interface IUpdateWorkspaceSolutionsServiceRequestBody {
  solutions: Omit<IWorkspaceSolution, 'id'>[];
}

export interface IUpdateWorkspaceSolutionsServiceResponse
  extends IWorkspaceSolutionsServiceResponse {}

/**************************************************************************************************
 * GET /workspaces/{workspaceId}/credentails
 **************************************************************************************************/

export interface IBasicUserResponse {
  id: string;
  name: string;
  email: string;
  avatarUrl: string | null;
}

export interface IWorkspaceCredential {
  id: string;
  name: string;
  provider: string;
  description: string;
  createdAt: Date;
  createdByUser: IBasicUserResponse;
}

export interface IWorkspaceCredentialsServiceResponse {
  data: IWorkspaceCredential[];
}

export interface IWorkspaceCredentailsServiceParamsPath {
  workspaceId: string;
}

/**************************************************************************************************
 * POST /workspaces/{workspaceId}/credentails
 **************************************************************************************************/

export interface ICreateWorkspaceCredentialsRequestBody {
  provider: string;
  name: string;
  value: string;
}

export interface ICreateWorkspaceCredentialsResponse extends IWorkspaceCredential {}

/**************************************************************************************************
 * POST /workspaces/{workspaceId}/users
 **************************************************************************************************/

export interface IAddWorkspaceUsersRequestBody {
  userIds: string[];
}

export interface IAddWorkspaceUsersResponse {}

/**************************************************************************************************
 * GET /workspaces/{workspaceId}/users
 **************************************************************************************************/

export interface IWorkspaceUser extends IUser {
  addedToWorkspaceAt: string;
}

export interface IWorkspaceUsersServiceResponse {
  data: IWorkspaceUser[];
}

export interface IWorkspaceUsersServiceParamsPath {
  workspaceId: string;
}

export interface IWorkspaceUsersServiceParamsQuery {
  name?: string;
}

/**************************************************************************************************
 * POST /workspaces/{workspaceId}/projects
 **************************************************************************************************/

export interface ICreateWorkspaceProjectRequestBody {
  name: string;
  description: string;
}

export interface ICreateWorkspaceProjectResponse extends IWorkspaceProject {}

export interface ICreateWorkspaceProjectParamsPath {
  workspaceId: string;
}

/**************************************************************************************************
 * GET /workspaces/{workspaceId}/projects
 **************************************************************************************************/

export interface IWorkspaceProjectsResponse extends IPlatformApiPaginatedResponse {
  data: IWorkspaceProject[];
}

export interface IWorkspaceProjectsParamsPath {
  workspaceId: string;
}

export interface IWorkspaceProjectsParamsQuery extends IPlatformApiPaginatedParamsQuery {
  direction?: 'ASC' | 'DESC';
  name?: string;
  sortProperty?: keyof KeysToSnakeCase<Pick<IWorkspaceProject, 'name' | 'createdAt'>>;
}

/**************************************************************************************************
 * GET /workspaces/{workspaceId}/projects/{projectId}
 **************************************************************************************************/

export interface IWorkspaceProjectServiceResponse
  extends IPlatformApiResourceResponse<IWorkspaceProject> {}

export interface IWorkspaceProjectServiceParamsPath {
  workspaceId: string;
  projectId: string;
}

/**************************************************************************************************
 * PUT /workspaces/{workspaceId}/projects/{projectId}
 **************************************************************************************************/

export interface IUpdateWorkspaceProjectServiceRequestBody {
  name: string;
  description: string;
}

export interface IUpdateWorkspaceProjectServiceResponse extends IWorkspaceProject {}

export interface IUpdateWorkspaceProjectServiceParamsPath {
  projectId: string;
  workspaceId: string;
}

/**************************************************************************************************
 * PUT /workspaces/{workspaceId}/projects/{projectId}/bookmarks
 **************************************************************************************************/

export interface IUpdateBookmark {
  name: string;
  url: string;
  logoUrl?: string | null;
}

export interface IUpdateWorkspaceProjectBookmarksServiceBody {
  bookmarks: IUpdateBookmark[];
}

export interface IUpdateWorkspaceProjectBookmarksResponse {}

/**************************************************************************************************
 * PUT /workspaces/{workspaceId}/projects/{projectId}/users
 **************************************************************************************************/

export interface IUpdateWorkspaceProjectUsersServiceBody {
  userIds: string[];
}

/**************************************************************************************************
 * GET /workspaces/{workspaceId}/projects/{projectId}/users
 **************************************************************************************************/
export interface IWorkspaceProjectUsersServiceResponse {
  data: IUser[];
}

/**************************************************************************************************
 * GET /workspaces/{workspaceId}/projects/{projectId}/files
 **************************************************************************************************/

export interface IWorkspaceProjectFilesResponse extends IPlatformApiPaginatedResponse {
  data: IProjectFile[];
}

export interface IWorkspaceProjectFilesParamsPath {
  workspaceId: string;
  projectId: string;
}

export interface IWorkspaceProjectFilesParamsQuery extends IPlatformApiPaginatedParamsQuery {
  app?: string;
}

/**************************************************************************************************
 * DELETE /workspaces/{workspaceId}/users/{userId}
 **************************************************************************************************/

export interface IDeleteWorkspaceUserResponse {}

export interface IDeleteWorkspaceUserParamsPath {
  workspaceId: string;
  userId: string;
}

/**************************************************************************************************
 * USERS
 **************************************************************************************************/

export type IUserRole = 'default' | 'admin';

export interface IUserIdentity {
  idp: string;
  idpUserId: string;
}

export interface IUser {
  id: string;
  name: string;
  email: string;
  avatarUrl: string | null;
  identity: IUserIdentity;
  permissions: string[];
  createdAt: string;
  updatedAt: string;
}

export interface IUserWithRole extends IUser {
  role: IUserRole;
}

export interface IUserWithWorkspaces extends IUserWithRole {
  workspaces: IWorkspace[];
}

/**************************************************************************************************
 * GET /users/me/apps
 **************************************************************************************************/

export interface IUserAppsServiceResponse {
  data: IApp[];
}

/**************************************************************************************************
 * GET /users/me/agreements
 **************************************************************************************************/

export interface IUserAgreementStatus {
  id: string;
  type: IAgreementType;
  accepted: boolean;
}
export interface IUserAgreementsServiceResponse {
  data: IUserAgreementStatus[];
}

/**************************************************************************************************
 * GET /users
 **************************************************************************************************/

export interface IUsersServiceResponse extends IPlatformApiOffsetPaginatedResponse {
  data: IUserWithWorkspaces[];
}

export interface IUsersServiceParamsQuery extends IPlatformApiOffsetPaginatedParamsQuery {
  search?: string;
  sortProperty?: keyof KeysToSnakeCase<Pick<IUser, 'name'>>;
  direction?: 'ASC' | 'DESC';
}

/**************************************************************************************************
 * GET /users/count
 **************************************************************************************************/

export interface ICountUsersServiceResponse {
  count: number;
}

/**************************************************************************************************
 * PUT /users/{userId}
 **************************************************************************************************/
export interface IUpdateUserServiceParamsPath {
  userId: string;
}

export interface IUpdateUserServiceRequestBody {
  name?: string;
  avatarUrl?: string | null;
  permissions?: string[];
  role?: IUserRole;
  workspacesIds?: string[];
}

export interface IUpdateUserServiceResponse extends IUser {}

/**************************************************************************************************
 * AGREEMENTS
 **************************************************************************************************/

export enum IAgreementType {
  EULA = 'EULA',
}
export interface IAgreement {
  id: string;
  type: IAgreementType;
  content: string;
}

/**************************************************************************************************
 * GET /agreements/{agreementId}
 **************************************************************************************************/

export interface IAgreementServiceParamsPath {
  agreementId: string;
}

export interface IAgreementServiceResponse extends IAgreement {}

/**************************************************************************************************
 * POST /agreements/{agreementId}/acknowledgments
 **************************************************************************************************/

export interface IAgreementAcknowledgementsServiceParamsPath {
  agreementId: string;
}

export interface IAgreementAcknowledgementsServiceResponse {}

/**************************************************************************************************
 * INVITATIONS
 **************************************************************************************************/

export enum IInvitationStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REVOKED = 'revoked',
  EXPIRED = 'expired',
}

export interface IInvitation {
  id: string;
  email: string;
  role: IUserRole;
  workspaces: IWorkspace[];
  permissions: IPermission[];
  status: IInvitationStatus;
  dateSent: string | null;
  expiresAt: string | null;
  organization: {
    id: string;
    name: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
}

/**************************************************************************************************
 * POST /invitations
 **************************************************************************************************/

export interface ICreateInvitationRequestBody {
  email: string;
  permissionsKeys?: string[];
  role: IUserRole;
  workspaceIds?: string[];
}

export interface ICreateInvitationResponse extends IInvitation {}

/**************************************************************************************************
 * PUT /invitations/{id}
 **************************************************************************************************/

export interface IUpdateInvitationServiceParamsPath {
  invitationId: string;
}

export interface IUpdateInvitationRequestBody {
  permissionsKeys?: string[];
  role: IUserRole;
  workspaceIds?: string[];
}

export interface IUpdateInvitationResponse extends IInvitation {}

/**************************************************************************************************
 * GET /invitations
 **************************************************************************************************/

export interface IInvitationsServiceResponse {
  data: IInvitation[];
}

/**************************************************************************************************
 * POST /invitations/{id}/actions/resend
 **************************************************************************************************/

export interface IResendInvitationResponse extends IInvitation {}

/**************************************************************************************************
 * POST /invitations/{id}/actions/revoke
 **************************************************************************************************/

export interface IRevokeInvitationParamsPath {
  invitationId: string;
}

export interface IRevokeInvitationResponse extends IInvitation {}

/**************************************************************************************************
 * AUTH
 **************************************************************************************************/

export interface IAuthMeServiceResponse {
  id: string;
  name: string;
  email: string;
  workspaceId: string;
  organizationId: string;
  workspaces: IWorkspace[];
  sessionExpiration: number;
  permissions: string[];
}

export interface IAuthOrganizationMethod {
  id: string;
  name: string;
}

/**************************************************************************************************
 * GET /auth/organizations/{organizationId}/methods
 **************************************************************************************************/

export interface IAuthOrganizationMethodsResponse {
  data: IAuthOrganizationMethod[];
}

/**************************************************************************************************
 * APPS
 **************************************************************************************************/

export interface IApp {
  id: string;
  name: string;
  description: string | null;
  slug: string;
  logo: string;
  hostedUrl: string;
  files: {
    displayName: string;
    name: string;
    intents: {
      name: string;
      template: string;
    }[];
  }[];
  manifest: {
    intents: {
      openProject?: string;
      openWorkspace?: string;
    } | null;
  } | null;
}

/**************************************************************************************************
 * GET /apps
 **************************************************************************************************/

export interface IAppsServiceResponse {
  data: IApp[];
}

/**************************************************************************************************
 * UPLOADS
 **************************************************************************************************/

/**************************************************************************************************
 * POST /uploads/images
 **************************************************************************************************/

export interface IUpload {
  url: string;
}

export interface IUploadsImagesRequestBody {
  file: File;
}

export interface IUploadsImagesResponse extends IUpload {}
