import { PLUGIN_PROVIDERS } from '@monksflow/plugin-providers/web';
import { Chip, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
  getWorkspaceCredentialsQueryKey,
  useCreateWorkspaceCredential,
  useWorkspaceCredentials,
} from 'api/platformApi/services/workspaceCredentialsService/hooks';
import AppDrawer from 'components/AppDrawer/AppDrawer';
import AppDrawerContent from 'components/AppDrawer/components/AppDrawerContent/AppDrawerContent';
import AppDrawerHeader from 'components/AppDrawer/components/AppDrawerHeader/AppDrawerHeader';
import { AppDrawerSubHeader } from 'components/AppDrawer/components/AppDrawerSubHeader/AppDrawerSubHeader.styles';
import MFButtonLoader from 'components/ui/MFButtonLoader/MFButtonLoader';
import RHFMonkTextField from 'components/ui/rhf-components/RHFMonkTextField/RHFMonkTextField';
import {
  getMaxLengthValidation,
  requiredValidation,
} from 'components/ui/rhf-components/utils/validationRules';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Styled from './AddWorkspaceCredentialDrawer.styles';
import WorkspaceCredentialCard from './WorkspaceCredentialCard';

export const API_KEY__MAX_LENGTH = 256;
export const FRIENDLY_NAME_MAX_LENGTH = 100;

interface AddPluginCredentialDrawerProps {
  open: boolean;
  onClose: () => void;
  provider: string;
  workspaceId: string;
}

interface AddPluginCredentialForm {
  apiKey: string;
  friendlyName: string;
}

const AddPluginCredentialDrawer: FC<AddPluginCredentialDrawerProps> = ({
  open,
  onClose,
  provider,
  workspaceId,
}) => {
  const snackbar = useSnackbarContext();
  const queryClient = useQueryClient();
  const { data } = useWorkspaceCredentials({ workspaceId });
  const credentials = data?.filter((c) => c.provider === provider) ?? [];
  const providerData = PLUGIN_PROVIDERS.find((p) => p.provider === provider);
  const isCreateMode = credentials.length === 0;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
  } = useForm<AddPluginCredentialForm>({
    mode: 'all',
    defaultValues: {
      apiKey: '',
      friendlyName: '',
    },
  });

  const createWorkspaceCredential = useCreateWorkspaceCredential({
    onSuccess() {
      void queryClient.invalidateQueries({
        queryKey: getWorkspaceCredentialsQueryKey({ workspaceId }),
      });
      reset();
      snackbar.success('New credential created');
    },
  });

  const onSubmit: SubmitHandler<AddPluginCredentialForm> = async (data) => {
    await createWorkspaceCredential.mutateAsync({
      workspaceId: workspaceId,
      provider,
      name: data.friendlyName,
      value: data.apiKey,
    });
  };

  const isSubmitDisabled = !isValid || isSubmitting;

  return (
    <AppDrawer open={open} onClose={onClose}>
      <Styled.Form onSubmit={handleSubmit(onSubmit)}>
        <AppDrawerHeader title="Add Plugin Credential" onClose={onClose} />
        <AppDrawerSubHeader>
          <Styled.ProviderContainer>
            <Typography variant="titleLargeMd">{providerData?.name}</Typography>
            {!isCreateMode ? (
              <Chip label="Connected" color="success" size="small" />
            ) : (
              <Chip label="Disconnected" color="warning" size="small" />
            )}
          </Styled.ProviderContainer>
        </AppDrawerSubHeader>
        <AppDrawerContent>
          {!isCreateMode && (
            <>
              {credentials.map((credential) => (
                <WorkspaceCredentialCard
                  key={credential.id}
                  workspaceId={workspaceId}
                  onDeleteSuccess={() => {
                    void queryClient.invalidateQueries({
                      queryKey: getWorkspaceCredentialsQueryKey({ workspaceId }),
                    });
                  }}
                  {...credential}
                />
              ))}
            </>
          )}
          {isCreateMode && (
            <Stack
              sx={{
                gap: 3,
              }}
            >
              <RHFMonkTextField
                name="apiKey"
                control={control}
                rules={{ ...requiredValidation, ...getMaxLengthValidation(API_KEY__MAX_LENGTH) }}
                label="API Key"
                placeholder="Enter API key..."
                disabled={isSubmitting}
                required
                fullWidth
              />

              <RHFMonkTextField
                name="friendlyName"
                control={control}
                rules={{
                  ...requiredValidation,
                  ...getMaxLengthValidation(FRIENDLY_NAME_MAX_LENGTH),
                }}
                label="Friendly Name"
                placeholder="ex. Client credential..."
                disabled={isSubmitting}
                required
                fullWidth
              />
            </Stack>
          )}
        </AppDrawerContent>
        {isCreateMode && (
          <Styled.Footer>
            <Styled.SaveButton
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isSubmitDisabled}
            >
              {isSubmitting ? <MFButtonLoader /> : 'Save'}
            </Styled.SaveButton>
          </Styled.Footer>
        )}
      </Styled.Form>
    </AppDrawer>
  );
};

export default AddPluginCredentialDrawer;
