import ErrorIcon from '@mui/icons-material/Error';
import { Alert, Box, Button, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import useCreateOrganization from 'api/platformApi/services/createOrganizationService/useCreateOrganization';
import { getOrganizationsQueryKey } from 'api/platformApi/services/organizationsService/useOrganizations';
import AppDrawer from 'components/AppDrawer/AppDrawer';
import AppDrawerContent from 'components/AppDrawer/components/AppDrawerContent/AppDrawerContent';
import AppDrawerFooter from 'components/AppDrawer/components/AppDrawerFooter/AppDrawerFooter';
import AppDrawerHeader from 'components/AppDrawer/components/AppDrawerHeader/AppDrawerHeader';
import SelectApplications from 'components/SelectApplications/SelectApplications';
import ClipboardTextField from 'components/ui/ClipboardTextField/ClipboardTextField';
import PasswordTextField from 'components/ui/PasswordTextField/PasswordTextField';
import RHFMonkTextField from 'components/ui/rhf-components/RHFMonkTextField/RHFMonkTextField';
import {
  URLValidation,
  emailValidation,
  getMaxLengthValidation,
  requiredValidation,
} from 'components/ui/rhf-components/utils/validationRules';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import useResetScrollPosition from 'hooks/useResetScrollPosition';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { AUTH_CALLBACK_URL } from 'utils/auth';
import {
  CreateOrganizationDrawerProps,
  CreateOrganizationFormFields,
} from './CreateOrganizationDrawer.types';

const CREATE_ORGANIZATION_FORM_ID = 'create-organization-form';
const DEFAULT_FORM_VALUES: CreateOrganizationFormFields = {
  name: '',
  administratorEmail: '',
  applications: [],
  authMethod: {
    methodName: '',
    issuerUrl: '',
    clientSecret: '',
    clientId: '',
  },
};
const ORGANIZATION_NAME_MAX_LENGTH = 100;

const CreateOrganizationDrawer: FC<CreateOrganizationDrawerProps> = ({ onClose, open }) => {
  const queryClient = useQueryClient();
  const snackbar = useSnackbarContext();
  const scrollableContainerRef = useResetScrollPosition([open]);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    reset,
  } = useForm<CreateOrganizationFormFields>({
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const createOrganizationMutation = useCreateOrganization({
    onError(error) {
      if (error.scope === 'field' && error.field) {
        setError(error.field, { message: error.message });
      }
    },
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: getOrganizationsQueryKey() });

      reset(DEFAULT_FORM_VALUES);
      onClose();
      snackbar.success('New organization created');
    },
  });

  const onCreateOrganizationSubmit: SubmitHandler<CreateOrganizationFormFields> = async (data) => {
    await createOrganizationMutation.mutateAsync({
      name: data.name,
      administratorEmail: data.administratorEmail,
      authMethod: data.authMethod,
      appIds: data.applications.map((app) => app.id),
    });
  };

  const isSubmitDisabled = !isValid || isSubmitting;

  return (
    <AppDrawer open={open} onClose={onClose}>
      <AppDrawerHeader title="Create Organization" onClose={onClose} />

      <AppDrawerContent ref={scrollableContainerRef}>
        <form id={CREATE_ORGANIZATION_FORM_ID} onSubmit={handleSubmit(onCreateOrganizationSubmit)}>
          {createOrganizationMutation.isError &&
            createOrganizationMutation.error.scope === 'alert' && (
              <Alert severity="error" icon={<ErrorIcon />}>
                {createOrganizationMutation.error.message}
              </Alert>
            )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
              }}
            >
              <Typography
                component="h2"
                variant="titleMedium"
                sx={{
                  color: 'text.primary',
                }}
              >
                1. Organization Name
                <RequiredAsterisk />
              </Typography>

              <RHFMonkTextField
                control={control}
                disabled={isSubmitting}
                label="Name"
                name="name"
                placeholder="Enter name..."
                rules={{
                  ...requiredValidation,
                  ...getMaxLengthValidation(ORGANIZATION_NAME_MAX_LENGTH),
                }}
                required
                fullWidth
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
              }}
            >
              <Typography
                component="h2"
                variant="titleMedium"
                sx={{
                  color: 'text.primary',
                }}
              >
                2. Invite organization administrator
                <RequiredAsterisk />
              </Typography>

              <RHFMonkTextField
                control={control}
                disabled={isSubmitting}
                label="Admin email"
                name="administratorEmail"
                placeholder="username@email.com"
                rules={{
                  ...emailValidation,
                  ...requiredValidation,
                }}
                type="email"
                required
                fullWidth
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
              }}
            >
              <Controller
                name="applications"
                control={control}
                render={({ field }) => (
                  <SelectApplications
                    label="3. Enable applications"
                    value={field.value}
                    onChange={field.onChange}
                    disabled={isSubmitting}
                  />
                )}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
              }}
            >
              <Typography
                component="h2"
                variant="titleMedium"
                sx={{
                  color: 'text.primary',
                }}
              >
                4. Enable authentication method
                <RequiredAsterisk />
              </Typography>

              <RHFMonkTextField
                control={control}
                disabled={isSubmitting}
                label="Authentication method name"
                name="authMethod.methodName"
                placeholder="Google"
                rules={{
                  ...requiredValidation,
                }}
                required
                fullWidth
              />

              <ClipboardTextField
                aria-readonly
                helperText="URL to share with client for configuration in their IDP"
                label="Callback url"
                onCopy={() => {
                  snackbar.success('URL copied to clipboard');
                }}
                type="url"
                value={AUTH_CALLBACK_URL}
              />

              <RHFMonkTextField
                control={control}
                disabled={isSubmitting}
                helperText="URL at which your identity-provider's OpenID Configuration Document can be found"
                label="Issuer url"
                name="authMethod.issuerUrl"
                placeholder="https://login.dev.monksflow.com"
                rules={{
                  ...URLValidation,
                  ...requiredValidation,
                }}
                type="url"
                slotProps={{
                  htmlInput: {
                    sx: {
                      flex: 1,
                    },
                  },
                  input: {
                    endAdornment: (
                      <Typography
                        color="primary"
                        noWrap
                        sx={{
                          fontSize: '0.875rem',
                        }}
                      >
                        /.well-known/openid-configuration
                      </Typography>
                    ),
                    disableUnderline: true,
                  },
                }}
                required
                fullWidth
              />

              <RHFMonkTextField
                control={control}
                disabled={isSubmitting}
                helperText="Is the unique identifier for the Monks.Flow application you created in your identity provider"
                label="Client ID"
                name="authMethod.clientId"
                placeholder="6779ef20e75817b79602"
                rules={{
                  ...requiredValidation,
                }}
                required
                fullWidth
              />

              <Controller
                control={control}
                disabled={isSubmitting}
                rules={{
                  ...requiredValidation,
                }}
                name="authMethod.clientSecret"
                render={({ field }) => (
                  <PasswordTextField
                    disabled={isSubmitting}
                    helperText="Is a secret or password generated for the Monks.Flow application that you created"
                    label="Client secret"
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    value={field.value}
                    placeholder="*************"
                    fullWidth
                    required
                  />
                )}
              />
            </Box>
          </Box>
        </form>
      </AppDrawerContent>

      <AppDrawerFooter>
        <Box
          sx={{
            marginLeft: 'auto',
          }}
        >
          <Button
            color="primary"
            disabled={isSubmitDisabled}
            form={CREATE_ORGANIZATION_FORM_ID}
            size="large"
            type="submit"
            variant="contained"
          >
            Create organization
          </Button>
        </Box>
      </AppDrawerFooter>
    </AppDrawer>
  );
};

export default CreateOrganizationDrawer;

const RequiredAsterisk: FC = () => (
  <Typography
    component="span"
    variant="titleMedium"
    sx={{
      color: 'error.main',
    }}
  >
    *
  </Typography>
);
