import { Box, TableCell, Typography } from '@mui/material';
import { IWorkspace, IWorkspaceProject } from 'api/platformApi/platformApi.types';
import VisibilityAvatar from 'components/VisibilityAvatar/VisibilityAvatar';
import MFTableBodyRow from 'components/ui/MFTableBodyRow/MFTableBodyRow';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { formatRelativeDate } from 'utils/dates';
import { routes } from '../../../../../../utils/routes';

interface ProjectsTableRowProps {
  project: IWorkspaceProject;
  workspaceSlug: IWorkspace['slug'];
}

export const ProjectsTableRow = forwardRef<HTMLTableRowElement, ProjectsTableRowProps>(
  ({ project, workspaceSlug }, ref) => {
    const projectRoute = routes.viewProject(workspaceSlug, project.id);

    return (
      <MFTableBodyRow key={project.id} ref={ref}>
        <TableCell>
          <Typography
            variant="bodyMedium"
            component={Link}
            to={projectRoute}
            sx={{
              color: 'text.primary',
              display: 'inline-block',
              width: 1,
              textDecoration: 'none',
            }}
          >
            {project.name}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="bodyMedium"
            component={Link}
            to={projectRoute}
            sx={{
              color: 'text.primary',
              display: 'inline-block',
              textDecoration: 'none',
            }}
          >
            {project.owner.name}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="bodyMedium"
            component={Link}
            to={projectRoute}
            sx={{
              color: 'text.primary',
              display: 'inline-block',
              width: 1,
              textDecoration: 'none',
            }}
          >
            {formatRelativeDate(project.createdAt)}
          </Typography>
        </TableCell>

        <TableCell>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <VisibilityAvatar visibility={project.visibility} />
            <Typography variant="bodyMedium">
              {project.visibility === 'private' ? `Invited members` : `Workspace`}
            </Typography>
          </Box>
        </TableCell>
      </MFTableBodyRow>
    );
  },
);

ProjectsTableRow.displayName = 'ProjectsTableRow';
