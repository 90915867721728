import { Box, Button, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { organizationAuthMethodServiceQueryKey } from 'api/platformApi/services/organizationAuthMethodService/useOrganizationAuthMethod';
import { getOrganizationAuthMethodsQueryKey } from 'api/platformApi/services/organizationAuthMethodsService/useOrganizationAuthMethods';
import useUpdateOrganizationAuthMethod from 'api/platformApi/services/updateOrganizationAuthMethodService/useUpdateOrganizationAuthMethod';
import AppDrawer from 'components/AppDrawer/AppDrawer';
import AppDrawerContent from 'components/AppDrawer/components/AppDrawerContent/AppDrawerContent';
import AppDrawerFooter from 'components/AppDrawer/components/AppDrawerFooter/AppDrawerFooter';
import AppDrawerHeader from 'components/AppDrawer/components/AppDrawerHeader/AppDrawerHeader';
import ClipboardTextField from 'components/ui/ClipboardTextField/ClipboardTextField';
import MFButtonLoader from 'components/ui/MFButtonLoader/MFButtonLoader';
import PasswordTextField from 'components/ui/PasswordTextField/PasswordTextField';
import RHFMonkTextField from 'components/ui/rhf-components/RHFMonkTextField/RHFMonkTextField';
import {
  URLValidation,
  requiredValidation,
} from 'components/ui/rhf-components/utils/validationRules';
import useSnackbarContext from 'contexts/snackbar/useSnackbarContext';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { AUTH_CALLBACK_URL } from 'utils/auth';
import { EditAuthMethodDrawerProps, EditAuthMethodFormFields } from './EditAuthMethodDrawer.types';

const UPDATE_AUTH_METHOD_FORM_ID = 'edit-auth-method-form';

const EditAuthMethodDrawer: FC<EditAuthMethodDrawerProps> = ({
  organizationId,
  method,
  onClose,
  open,
}) => {
  const queryClient = useQueryClient();
  const snackbar = useSnackbarContext();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<EditAuthMethodFormFields>({
    defaultValues: {
      methodName: method.name,
      issuerUrl: method.issuerUrl,
      clientId: method.clientId,
      clientSecret: method.clientSecret,
    },
  });

  const updateAuthMethodMutation = useUpdateOrganizationAuthMethod(
    {
      organizationId,
      methodId: method.id,
    },
    {
      onError: () => {
        snackbar.error('An error occurred and the method could not be updated. Please try again.');
      },
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getOrganizationAuthMethodsQueryKey(organizationId),
        });
        await queryClient.invalidateQueries({
          queryKey: organizationAuthMethodServiceQueryKey({ organizationId, methodId: method.id }),
        });
        onClose();
        snackbar.success('The authentication method was updated successfully!');
      },
    },
  );

  const onUpdateAuthMethodSubmit: SubmitHandler<EditAuthMethodFormFields> = async (data) => {
    await updateAuthMethodMutation.mutateAsync({
      methodName: data.methodName,
      issuerUrl: data.issuerUrl,
      clientId: data.clientId,
      clientSecret: data.clientSecret,
    });
  };

  const isSubmitDisabled = !isValid || isSubmitting;

  return (
    <AppDrawer open={open} onClose={onClose}>
      <AppDrawerHeader title="Update authentication method" onClose={onClose} />

      <AppDrawerContent>
        <form id={UPDATE_AUTH_METHOD_FORM_ID} onSubmit={handleSubmit(onUpdateAuthMethodSubmit)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
            }}
          >
            <RHFMonkTextField
              control={control}
              disabled={isSubmitting}
              label="Authentication method name"
              name="methodName"
              placeholder="Google"
              rules={{
                ...requiredValidation,
              }}
              required
              fullWidth
            />

            <ClipboardTextField
              aria-readonly
              helperText="URL to share with client for configuration in their IDP"
              label="Callback url"
              onCopy={() => {
                snackbar.success('URL copied to clipboard');
              }}
              type="url"
              value={AUTH_CALLBACK_URL}
            />

            <RHFMonkTextField
              control={control}
              disabled={isSubmitting}
              helperText="URL at which your identity-provider's OpenID Configuration Document can be found"
              label="Issuer url"
              name="issuerUrl"
              placeholder="https://login.dev.monksflow.com"
              rules={{
                ...URLValidation,
                ...requiredValidation,
              }}
              type="url"
              slotProps={{
                htmlInput: {
                  sx: {
                    flex: 1,
                  },
                },
                input: {
                  endAdornment: (
                    <Typography
                      color="primary"
                      noWrap
                      sx={{
                        fontSize: '0.875rem',
                      }}
                    >
                      /.well-known/openid-configuration
                    </Typography>
                  ),
                  disableUnderline: true,
                },
              }}
              required
              fullWidth
            />

            <RHFMonkTextField
              control={control}
              disabled={isSubmitting}
              helperText="Is the unique identifier for the Monks.Flow application you created in your identity provider"
              label="Client ID"
              name="clientId"
              placeholder="6779ef20e75817b79602"
              rules={{
                ...requiredValidation,
              }}
              required
              fullWidth
            />

            <Controller
              control={control}
              disabled={isSubmitting}
              rules={{
                ...requiredValidation,
              }}
              name="clientSecret"
              render={({ field }) => (
                <PasswordTextField
                  disabled={isSubmitting}
                  helperText="Is a secret or password generated for the Monks.Flow application that you created"
                  label="Client secret"
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                  placeholder="*************"
                  fullWidth
                  required
                />
              )}
            />
          </Box>
        </form>
      </AppDrawerContent>

      <AppDrawerFooter>
        <Box
          sx={{
            marginLeft: 'auto',
          }}
        >
          <Button
            color="primary"
            disabled={isSubmitDisabled}
            form={UPDATE_AUTH_METHOD_FORM_ID}
            size="large"
            type="submit"
            variant="contained"
          >
            {isSubmitting ? <MFButtonLoader /> : `Save changes`}
          </Button>
        </Box>
      </AppDrawerFooter>
    </AppDrawer>
  );
};

export default EditAuthMethodDrawer;
