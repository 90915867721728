import { TextField as MuiTextField, Button, Box } from '@mui/material';
import { AppDrawerFooter } from 'components/AppDrawer/components/AppDrawerFooter/AppDrawerFooter.styles';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TextField = styled(MuiTextField)`
  margin-bottom: 16px;
`;

export const Footer = styled(AppDrawerFooter)`
  justify-content: end;
`;

export const SaveButton = styled(Button)`
  min-width: 122px;
`;

export const ProviderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CredentialRow = styled(Box)`
  padding: 16px;
  background: ${({ theme }) => theme.palette.surface.level2};
  border-radius: 16px;
`;
